<template>
    <a-modal
        :visible="visible"
        :title="title"
        :width="700"
        :maskClosable="false"
        @cancel="handleCancelConfirm"
    >
        <div style="margin-bottom: 20px;">考虑周期后段发布的短视频，传播互动数据会延迟反应，通过指标延时统计，便于更客观反映并跟踪周期KPI数据的达成情况。</div>
        <a-form-model
            ref="form"
            :model="form"
            :rules="rules"
            v-bind="layout"
        >
            <a-form-model-item label="指标延时统计" prop="isOpenDelayStatisticsDay">
                <a-switch v-model="form.isOpenDelayStatisticsDay" />
            </a-form-model-item>
            <a-form-model-item label="延时统计天数" prop="delayStatisticsDay" extra="不可超过15天" v-if="form.isOpenDelayStatisticsDay">
                <a-input-number v-model="form.delayStatisticsDay" :min="0" :max="15" :precision="0" style="width: 150px;" />
            </a-form-model-item>
        </a-form-model>
        <template slot="footer">
            <a-row>
                <a-col :span="16" style="text-align: left;">
                    <span>提示：变更延时统计设置将影响KPI结果统计，请谨慎操作！<br>12点前更新，当日生效；12点后更新，次日生效。</span>
                </a-col>
                <a-col :span="8">
                    <base-button
                        :title="'取消'"
                        @onClickBtn="handleCancelConfirm"
                    >
                    </base-button>
                    <base-button
                        :type="'primary'"
                        :title="'保存'"
                        :loading="isSubmit"
                        :disabled="btnStatus || isSubmit"
                        @onClickBtn="handleSubmit"
                    >
                    </base-button>
                </a-col>
            </a-row>
        </template>
    </a-modal>
</template>

<script>
import _API from "@/api/projectManagement";

export default {
    name: "assessmentCycleDrawer",
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "指标延时统计",
        },
        projectId: {
            type: String,
            default: "",
        },
        btnStatus: {
            type: Boolean,
            default: false
        },
    },
    watch: {
        visible: {
            handler(val) {
                if(val) {
                    this.selectDelayDay()
                }
            },
            immediate: true,
        },
    },
    data() {
        return {
            form: {
                id: this.$route.query.projectId,
                isOpenDelayStatisticsDay: false,
                delayStatisticsDay: ''
            },
            rules: {
                isOpenDelayStatisticsDay: [
                    { required: true, message: '请选择指标延时统计', trigger: 'change' }
                ],
                delayStatisticsDay: [
                    { required: true, message: '请选择延时统计天数', trigger: 'change' }
                ],
            },
            layout: {
                labelCol: { span: 4 },
                wrapperCol: { span: 20 }
            },
            isSubmit: false,
        }
    },
    methods: {
        selectDelayDay() {
            _API.selectDelayDay({ id: this.projectId }).then(res => {
                if(res.code == 200) {
                    let _data = res.data
                    this.form = {
                        ...this.form,
                        ..._data
                    }
                } else {
                    this.$message.error(`获取失败，${res.message}`)
                }
            })
        },
        handleCancelConfirm() {
            this.$emit("handleCancel");
        },
        handleSubmit() {
            this.$refs.form.validate((valid, obj) => {
                if (valid) {
                    let that = this
                    that.$confirm({
                        title: "变更指标延时统计设置将影响KPI结果统计，确定保存？",
                        content: "提示：12点前更新，当日生效；12点后更新，次日生效。",
                        okText: "确定",
                        cancelText: "取消",
                        onOk() {
                            that.saveOrUpdateDelayDay();
                        },
                    });
                }
            })
        },
        saveOrUpdateDelayDay() {
            this.isSubmit = true
            if(!this.form.isOpenDelayStatisticsDay) {
                this.form.delayStatisticsDay = 0
            }
            _API.saveOrUpdateDelayDay(this.form).then(res => {
                this.isSubmit = false
                if(res.code == 200) {
                    this.handleCancelConfirm()
                    this.$message.success(`指标延时统计设置成功`)
                    location.reload()
                } else {
                    this.$message.error(`保存失败，${res.message}`)
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
</style>