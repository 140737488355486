<template>
  <div class="box">
    <h2>学员KPI</h2>

    <a-form-model ref="searchForm" :model="searchForm" v-bind="layout">
      <a-row :gutter="16">
        <a-col :span="8">
          <a-form-model-item label="学员分组">
            <a-select v-model="searchForm.groupId" placeholder="请选择">
              <a-select-option v-for="item in traineeList" :key="item.traineeGroupId">
                  {{ item.traineeGroupName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="学员名称">
            <a-input v-model.trim="searchForm.traineeName" allow-clear placeholder="请输入" />
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="指标显示名">
            <a-select v-model="searchForm.assessmentNormId" placeholder="请选择">
              <a-select-option v-for="item in assessmentNormList" :key="item.id" :value="item.id">
                {{ item.normViewName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col class="flex-end">
          <ASpace>
            <a-button icon="redo" @click="resetSearch">
              重置
            </a-button>
            <a-button type="primary" icon="search" @click="bindSearch">
              查询
            </a-button>
            <a-button type="primary" icon="form" v-if="editBtn" :disabled="btnStatus" @click="changeBtnSave(1)">
              修改
            </a-button>
            <a-button type="primary" icon="form" v-else :loading="isSubmit" @click="changeBtnSave(2)">
              保存
            </a-button>
          </ASpace>
        </a-col>
      </a-row>
    </a-form-model>

    <div class="data-table">
      <a-table :loading="loading" :columns="columns" :data-source="list" :row-key="(record, index) => index"
        :pagination="false" :scroll="{ x: true }" :locale="{ emptyText: getLocaleEmpty }">
        <div slot="traineeInfo" slot-scope="text, record">
          <div>{{ record.traineeName || '-' }}</div>
          <div>
            <a-tooltip placement="rightTop">
              <template slot="title">
                <span>{{ record.groupNameList }}</span>
              </template>
              <div class="group-box">分组：{{ record.groupNameList }}</div>
            </a-tooltip>
          </div>
        </div>
        <div slot="assessmentInfo" slot-scope="text, record">
          <div>显示名：<a @click="showIndexDrawer('detail', record)">{{ record.normViewName || '-' }}</a></div>
          <div>分类：{{ record.normTypeName || '-' }}</div>
        </div>
      </a-table>
      <base-pagination :current-page="pagination.current" :page-size="pagination.pageSize" :total="pagination.total"
        @onChange="handlePaginationChange" @onShowSizeChange="handlePaginationChange" />
    </div>

    <!--指标抽屉-->
    <indexDrawer ref="indexDrawer" :visible="indexDrawer" :title="'指标详情'" :assessment-norm-id="assessmentNormId"
      :kpi-business-id="kpiBusinessId" :is_detail="3" @handleCancel="handleIndexDrawerCancel" />
  </div>
</template>

<script>
import _API from "@/api/projectManagement";
import vEmpty from "../components/v-empty";
import indexDrawer from "../components/indexDrawer";
import { cloneDeep, isEmpty, keys, pick, flattenDeep, isEqual } from "lodash-es";

export default {
  name: "studentKPIPage",
  components: { vEmpty, indexDrawer },
  props: {
    projectId: {
      type: [String, Number],
    },
    kpiBusinessId: {
      type: [String, Number],
    },
    btnStatus: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchForm: {},
      layout: {
        labelCol: { span: 7 },
        wrapperCol: { span: 17 }
      },
      traineeList: [],
      assessmentNormList: [],
      loading: false,
      columns: [
        {
          title: '学员',
          scopedSlots: { customRender: 'traineeInfo' },
          width: 200
        },
        {
          title: '指标',
          scopedSlots: { customRender: 'assessmentInfo' },
          width: 200
        },
      ],
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      editBtn: true,
      isSubmit: false,
      indexDrawer: false,
      assessmentNormId: '',
      traineeList: []
    };
  },
  computed: {
    getLocaleEmpty() {
      if (this.traineeList.length < 1) {
        return <vEmpty text="未添加学员" onTo={this.handleGoEmpty.bind(this)} isLink />;
      } else {
        return <vEmpty text="暂无数据" />;
      }
    },
  },
  mounted() {
    this.getTraineeList()
    this.getTraineeGroupList()
    this.getPmsProjectBusinessNorm()
    this.getTraineeKpiList()
  },
  methods: {
    getTraineeList() {
      let params = {
        kpiBusinessId: this.kpiBusinessId,
      };
      _API.getTraineeList(params).then((res) => {
        if(res.code == 200) {
          this.traineeList = res.data.list || [];
        }
      });
    },
    handleGoEmpty() {
      let projectDetail_routePage = this.$router.resolve({
        name: "projectDetail",
        query: { tabActive: 1, id: this.projectId },
      });
      window.open(projectDetail_routePage.href, "_blank");
    },
    getTraineeGroupList() {
      _API.getTraineeGroupList({ businessId: this.kpiBusinessId }).then(res => {
        if (res.code == 200) {
          this.traineeList = res.data || []
        } else {
          this.$message.error(`获取分组失败，${res.message}`)
        }
      })
    },
    getPmsProjectBusinessNorm() {
      _API.getPmsProjectBusinessNorm({ projectId: this.projectId, page: 1, size: 1000 }).then(res => {
        if (res.code == 200) {
          this.assessmentNormList = res.data.list || []
        } else {
          this.$message.error(`获取显示名列表失败,${res.message}`)
        }
      })
    },
    bindSearch() {
      this.pagination.current = 1
      this.getTraineeKpiList();
    },
    resetSearch() {
      this.searchForm = {}
      this.pagination.pageSize = 10
      this.pagination.current = 1
      this.getTraineeKpiList()
    },
    handlePaginationChange(page, size) {
      this.pagination.current = page
      this.pagination.pageSize = size
      this.getTraineeKpiList()
    },
    getTraineeKpiList() {
      let params = {
        ...this.searchForm,
        businessId: this.kpiBusinessId,
        page: this.pagination.current,
        size: this.pagination.pageSize
      }
      this.loading = true
      this.editBtn = true
      _API.getTraineeKpiList(params).then(res => {
        this.loading = false

        if (res.code == 200) {
          let _data = res.data
          this.list = _data.list || []
          this.pagination.total = _data.total
          this.handleTableColumns(res.data?.list || []);
        } else {
          this.pagination.total = 0
          this.$message.error(`获取列表失败,${res.message}`)
        }
      })
    },
    handleTableColumns(list) {
      // console.log(list);
      this.columns = [
        {
          title: '学员',
          scopedSlots: { customRender: 'traineeInfo' },
          width: 200
        },
        {
          title: '指标',
          scopedSlots: { customRender: 'assessmentInfo' },
          width: 200
        },
      ]

      let titles = [];

      const newList = list.map(item => {
        const obj = {};
        const { cycleInfoList } = item;

        if (!isEmpty(cycleInfoList)) {
          if (titles.length <= cycleInfoList.length) {
            titles = cycleInfoList;
          }
          cycleInfoList.forEach((child, index) => {
            const key = `__custom_${index}__`;
            child.isPercentage = item.isPercentage
            obj[key] = child;
          });
        }

        return { ...item, ...obj };
      });

      titles.forEach((item, index) => {
        const key = `__custom_${index}__`;
        this.columns.push({
          title: item.assessmentCycleName,
          dataIndex: key,
          key,
          width: 130,
          customRender: (text, record) => {
            let isPercentage = record.isPercentage
            let symbol = isPercentage ? '%' : ''
            let isAssessment = record[key].isAssessment

            return this.editBtn ? (
              <div>
                {
                  isAssessment == 1 ? <div>
                    {record[key].assessmentTarget}{symbol}
                  </div> : isAssessment == 0 ? <span>不考核</span> : '-'
                }
              </div>
            ) : (
              <div>
                {isAssessment == 1 ? <div class="flex"><a-input-number
                  value={record[key].assessmentTarget}
                  placeholder={"请输入"}
                  precision={0}
                  max={isPercentage ? 100 : 100000000}
                  min={0}
                  disabled={!record[key].assessmentTarget && this.editBtn}
                  onChange={(val) => this.$set(record[key], "assessmentTarget", val)}
                  style="width:120px"
                />{symbol}</div> : isAssessment == 0 ? <span>不考核</span> : '-'}
              </div>
            );
          },
        });
      });

      this.list = newList || [];
    },
    changeBtnSave(type) {
      switch (type) {
        case 1:
          this.editBtn = false
          break;
        case 2:
          let that = this

          that.$confirm({
            title: '变更学员KPI将影响KPI结果统计，确定保存？',
            content: () => <div>提示：12点前更新，当日生效；12点后更新，次日生效。</div>,
            onOk() {
              that.saveTraineeKpiData()
            },
          })
          break;
      }
    },
    saveTraineeKpiData() {
      let data = []
      for(let list of this.list) {
        let state = undefined
        for(let item of list.cycleInfoList) {
          if(!((item.assessmentTarget ?? '') !== '') && item.isAssessment == 1) {
            this.$message.error("考核目标值不可为空！");
            state = 0
            return
          }
          if(item.isAssessment == 1) {
            state = 1
          }
        }

        if(state){
          data.push({
            assessmentNormId: list.assessmentNormId,
            traineeId: list.traineeId,
            cycleInfoList: list.cycleInfoList
          })
        }
      }

      this.isSubmit = true
      _API.saveTraineeKpiData(data, this.kpiBusinessId).then(res => {
        this.isSubmit = false
        if(res.code == 200) {
          this.$message.success('保存成功')
          this.getTraineeKpiList()
          this.editBtn = true
        } else {
          this.$message.error(`保存失败，${res.message}`)
        }
      })
    },
    showIndexDrawer(type, record) {
      let that = this;
      switch (type) {
        case "detail":
          that.indexDrawer = true;
          that.assessmentNormId = record.assessmentNormId;
          break;
      }
    },
    handleIndexDrawerCancel() {
      this.indexDrawer = false;
    },
  },
};
</script>

<style scoped lang="less">
.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.data-table {
  margin-top: 30px;
}

.mr-20 {
  margin-right: 20px;
}

.group-box {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
}
.flex {
  display: flex;
  align-items: center;
}
</style>
