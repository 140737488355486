<template>
    <a-modal
        :visible="visible"
        title="最近产出统计"
        :width="700"
        :maskClosable="false"
        @cancel="handleCancelConfirm"
    >
        <div style="margin-bottom: 20px;">为便于更直观反映学员的最近产出情况，可开启最近产出统计。开启后，会统计展示学员的最近产出日期以及最近连续零产天数，以便及时跟踪并督促学员达成周期KPI。</div>
        <a-form-model
            ref="form"
            :model="form"
            v-bind="layout"
        >
            <a-form-model-item label="最近产出统计" prop="isShowRecentDayOutput">
                <a-switch v-model="form.isShowRecentDayOutput" />
            </a-form-model-item>
        </a-form-model>
        <template slot="footer">
            <a-row>
                <a-col :span="16" style="text-align: left;">
                    <span>提示：变更最近产出统计设置将影响经销商KPI的产出统计展示，请谨慎操作！<br>12点前更新，当日生效；12点后更新，次日生效。</span>
                </a-col>
                <a-col :span="8">
                    <base-button
                        :title="'取消'"
                        @onClickBtn="handleCancelConfirm"
                    >
                    </base-button>
                    <base-button
                        :type="'primary'"
                        :title="'保存'"
                        :loading="isSubmit"
                        :disabled="btnStatus || isSubmit"
                        @onClickBtn="handleSubmit"
                    >
                    </base-button>
                </a-col>
            </a-row>
        </template>
    </a-modal>
</template>

<script>
import _API from "@/api/projectManagement";

export default {
    name: "assessmentCycleDrawer",
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        btnStatus: {
            type: Boolean,
            default: false
        },
    },
    watch: {
        visible: {
            async handler() {
                if(!this.visible) return;
                await this.getRecentDayOutput();
            }
        },
    },
    data() {
        return {
            projectId: this.$route.query.projectId,
            form: {
                isShowRecentDayOutput: false,
            },
            layout: {
                labelCol: { span: 4 },
                wrapperCol: { span: 20 }
            },
            isSubmit: false,
        }
    },
    methods: {
        getRecentDayOutput() {
            _API.getRecentDayOutput({id: this.projectId}).then(res => {
                if(res.code == 200) {
                    this.form.isShowRecentDayOutput = res.data.isShowRecentDayOutput ? true : false
                } else {
                    this.$message.error(`${res.message}`)
                }
            })
        },
        handleCancelConfirm() {
            this.$emit("handleCancel");
        },
        handleSubmit() {
            this.$refs.form.validate((valid, obj) => {
                if (valid) {
                    let that = this
                    that.$confirm({
                        title: "变更最近产出统计设置将影响经销商KPI的产出统计展示，确定保存？",
                        content: "提示：12点前更新，当日生效；12点后更新，次日生效。",
                        okText: "确定",
                        cancelText: "取消",
                        onOk() {
                            that.submitRecentDayOutput()
                        },
                    });
                }
            })
        },
        submitRecentDayOutput() {
            this.isSubmit = true
            let params = {
                id: this.projectId,
                isShowRecentDayOutput: this.form.isShowRecentDayOutput ? 1 : 0
            }
            _API.submitRecentDayOutput(params).then(res => {
                this.isSubmit = false
                if(res.code == 200) {
                    this.handleCancelConfirm()
                    this.$message.success(`最近产出统计设置成功`)
                    // location.reload()
                } else {
                    this.$message.error(`${res.message}`)
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
</style>