<template>
    <div>
        <a-drawer
            :visible="visible"
            class="custom-class"
            :title="title"
            placement="right"
            width="80%"
            @close="handleCancel"
        >
            <a-form-model
                ref="form"
                :model="form"
                :rules="rules"
                layout="inline"
                style="height:calc(100vh - 200px);overflow-y: auto"
            >
                <p class="title_text">基础信息</p>
                <div class="form_content">
                    <div>
                        <a-form-model-item label="指标分类">
                            <!-- {{ form.normType == 'AWEME' ? "短视频" : form.normType == 'LIVE' ? "直播" : "经销商" }} -->
                            <span v-if="form.normType == 'AWEME'">短视频</span>
                            <span v-else-if="form.normType == 'LIVE'">直播</span>
                            <span v-else-if="form.normType == 'DEALER'">经销商</span>
                            <span v-else-if="form.normType == 'AUTHOR'">播主</span>
                            <span v-else>-</span>
                        </a-form-model-item>
                    </div>
                    <div>
                        <a-form-model-item label="指标显示名">
                            {{ form.normViewName || '-' }}
                        </a-form-model-item>
                    </div>
                    <a-divider />
                </div>
                <p class="title_text">目标值设置<span class="txt">支持按学员分组批量设置考核目标值，如不同学员目标值不同，可修改
                    <router-link :to="{
                        name: 'KPIAssessmentSetting',
                        query: {
                            projectId: projectId,
                            kpiBusinessId: kpiBusinessId,
                            activeKey: 3
                        }
                    }" target="_blank">学员KPI
                    </router-link>。</span></p>
                <div class="form_content">
                    <div>
                        <a-form-model-item label="选择适用学员(分组)" prop="groupIdList">
                            <a-select
                                mode="multiple"
                                v-model="form.groupIdList"
                                placeholder="请选择适用学员(分组)"
                                show-search
                                option-filter-prop="children"
                                style="width: 350px"
                            >
                                <a-select-option
                                    v-for="item in groupList"
                                    :key="item.traineeGroupId"
                                >
                                    {{ item.traineeGroupName }}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </div>
                    <div style="margin-top: 30px;">
                        <a-table
                            :loading="loading"
                            :columns="columns"
                            :data-source="form.cycleInfoList"
                            :row-key="(record, index) => record.assessmentCycleId"
                            :pagination="false"
                            :scroll="{ x: 900,y: 300 }"
                            bordered
                            :row-selection="rowSelection"
                        >
                            <div slot="cycleName" slot-scope="text, record">
                                <span>{{ record.assessmentCycleName }}</span>
                                <!--<a-input-->
                                <!--    v-else-->
                                <!--    v-model="record.cycleName"-->
                                <!--    style="width: 90%"-->
                                <!--    :maxLength="10"-->
                                <!--    :disabled="is_edit"-->
                                <!--/>-->
                            </div>
                            <div slot="time" slot-scope="text, record">
                                <ASpace>
                                    <span>{{ record.startDate }}</span>
                                    <span>至</span>
                                    <span>{{ record.endDate }}</span>
                                </ASpace>
                            </div>
                            <div slot="assessmentTarget" slot-scope="text, record">
                                <span v-if="record.isAssessment == 0">不考核</span>
                                <a-input-number
                                    v-else
                                    v-model="record.assessmentTarget"
                                    placeholder="请输入"
                                    style="width:130px"
                                    :min="0"
                                    :max="form.isPercentage ? 100 : 100000000"
                                    :precision="0"
                                />
                                <span v-if="form.isPercentage && record.isAssessment != 0">%</span>
                            </div>
                        </a-table>
                    </div>
                </div>
            </a-form-model>
            <template solt="footer">
                <a-row style="padding-top: 40px">
                <a-col :span="20">
                    <span>提示：变更考核目标将影响KPI结果统计，请谨慎操作！<br>12点前更新，当日生效；12点后更新，次日生效。</span>
                </a-col>
                <a-col :span="4">
                    <ASpace>
                        <base-button
                            :title="'取消'"
                            @onClickBtn="handleCancel"
                        >
                        </base-button>
                        <base-button
                            :type="'primary'"
                            :title="'确定'"
                            @onClickBtn="handleSubmit"
                        >
                        </base-button>
                    </ASpace>
                </a-col>
                </a-row>
            </template>
        </a-drawer>

        <a-modal
            :visible="modalVisible"
            :footer="null"
            title="请确认当前考核目标分解设置："
            @cancel="handleModalCancel"
        >
            <a-radio-group v-model="form.applicationScope" button-style="solid">
                <a-radio-button value="1" style="width: 100%; text-align: center;">
                    对适用学员都生效
                </a-radio-button>
                <a-radio-button value="2" style="width: 100%; text-align: center; margin-top: 20px;">
                    仅对适用学员中未修改过KPI的学员生效
                </a-radio-button>
            </a-radio-group>
            <template solt="footer">
                <div style="padding-top: 20px; margin-top: 20px; border-top: 1px solid #cccccc; display: flex; justify-content: flex-end;">
                    <ASpace>
                        <base-button
                            :title="'取消'"
                            @onClickBtn="handleModalCancel"
                        >
                        </base-button>
                        <base-button
                            :type="'primary'"
                            :title="'确定'"
                            :loading="is_submit"
                            :disabled="is_submit"
                            @onClickBtn="handleSubmitData"
                        >
                        </base-button>
                    </ASpace>
                </div>
            </template>
        </a-modal>
    </div>
</template>

<script>
import _API from "@/api/projectManagement";

const stringToObject = (data) => {
    try {
        return JSON.parse(data);
    } catch(e) {
        return {};
    }
};

export default {
    name: "objectiveDrawer",
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        is_detail: {
            type: Number,
            default: 1,
        },
        title: {
            type: String,
            default: "添加指标",
        },
        kpiBusinessId: {
            type: [String, Number],
        },
        assessmentNormId: {
            type: [String, Number],
        },
        getRadioValue: {
            type: [String, Number],
        },
        project_data: {
            type: String,
            default: "",
        },
    },
    watch: {
        project_data: {
            handler() {
                this.project = stringToObject(this.project_data);
            },
            deep: true,
            immediate: true,
        },
        visible: {
            async handler() {
                if(!this.visible) return;
                
                if(this.assessmentNormId) this.getResolveDetailByNormId();
            },
        },
    },
    data() {
        return {
            projectId: this.$route.query.projectId,
            form: {},
            rules: {
                groupIdList: [
                    { required: true, message: '请选择适用学员(分组)', trigger: 'change' }
                ],
            },
            groupList: [],
            loading: false,
            columns: [
                {
                    title: "周期名称",
                    width: 160,
                    scopedSlots: {customRender: "cycleName"},
                },
                {
                    title: "时间范围",
                    width: 180,
                    scopedSlots: {customRender: "time"},
                },
                {
                    title: "天数",
                    width: 180,
                    dataIndex: 'intervalDay'
                },
                {
                    title: "考核目标值",
                    width: 140,
                    scopedSlots: {customRender: "assessmentTarget"},
                },
            ],
            is_edit: false,
            cycleInfoList: [],
            selectedRowKeys: [],
            selectedRows: [],
            is_submit: false,
            modalVisible: false,
        }
    },
    computed: {
        rowSelection() {
            const {selectedRowKeys, selectedRows} = this;
            const _this = this;
            return {
                selectedRowKeys,
                selectedRows,
                onChange: (selectedRowKeys, selectedRows) => {
                    // console.log(selectedRowKeys);
                    _this.selectedRowKeys = selectedRowKeys;
                    _this.selectedRows = selectedRows;
                    console.log(_this.selectedRows);
                },
                onSelect: (record, selected) => {
                    if(!selected) {
                        this.$set(record, "isAssessment", 0);
                        this.$set(record, "assessmentTarget", "");
                    } else {
                        this.$set(record, "isAssessment", 1);
                        this.$set(record, "assessmentTarget", "");
                    }
                },
                onSelectAll: (selected, selectedRows, changeRows) => {
                    if(selected && selectedRows) {
                        this.form.cycleInfoList = this.form.cycleInfoList.map(item => {
                            item.isAssessment = 1;
                            item.assessmentTarget = "";
                            return item;
                        });
                    } else {
                        this.form.cycleInfoList = this.form.cycleInfoList.map(item => {
                            item.isAssessment = 0;
                            item.assessmentTarget = "";
                            return item;
                        });
                    }
                },
            };
        },
    },
    methods: {
        handleCancel() {
            this.form = {}
            this.groupList = []
            this.cycleInfoList = []
            this.$emit("handleCancel");
        },
        handleSubmit() {
            this.$refs.form.validate((valid) => {
                if(valid) {
                    if(!this.selectedRows.every(v => (v.assessmentTarget ?? '') !== '')) {
                        this.$message.error('已勾选周期需设置考核目标值！')
                        return
                    }
                    
                    this.modalVisible = true
                }
            })
        },
        handleModalCancel() {
            this.modalVisible = false
        },
        handleSubmitData() {
            if(this.form.applicationScope) {
                // this.form.cycleInfoList = this.selectedRows

                _API.resolveNormTarget(this.form).then(res => {
                    if(res.code == 200) {
                        this.handleModalCancel()
                        this.handleCancel()
                        this.$message.success(`设置成功，可到【学员KPI】查看`)
                        location.href = `/KPIAssessmentSetting?projectId=${this.projectId}&kpiBusinessId=${this.kpiBusinessId}&activeKey=2`
                    } else {
                        this.$message.error(`提交失败，${res.message}`)
                    }
                })
            } else {
                this.$message.error('请选择当前考核目标分解设置')
            }
        },
        getGroupList() {
            let params = {
                businessId: this.kpiBusinessId,
                traineeGroupType: this.form.normType == 'AWEME' ? 1 : this.form.normType == 'LIVE' ? 2 : undefined
            }
            _API.getTraineeGroupList(params).then((res) => {
                if(res.code == 200) {
                    this.groupList = res.data || []
                } else {
                    this.$message.error(res.message || "获取分组失败")
                }
            });
        },
        getResolveDetailByNormId() {
            _API.getResolveDetailByNormId(this.assessmentNormId).then(res => {
                if(res.code == 200) {
                    let _data = res.data
                    this.form = _data
                    this.selectedRowKeys = _data.cycleInfoList.map(item => {
                        return item.assessmentCycleId
                    })

                    // this.cycleInfoList = _data.cycleInfoList
                    this.selectedRows = _data.cycleInfoList

                    this.getGroupList()
                } else {
                    this.$message.error(`获取详情失败，${res.message}`)
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
.title_text {
    font-size: 16px;
    font-weight: bold;
    .txt {
        padding-left: 20px;
        font-size: 12px;
        font-weight: normal;
    }
}

.title_text1 {
    padding-left: -8%;
}

.form_content {
    padding-left: 8%;
}

.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 20px 0;
    padding: 30px 0 20px 6%;
    border-top: 1px solid #cccccc
}
</style>
