<template>
    <a-drawer :visible="visible" class="custom-class" :title="title" placement="right" width="60%" @close="handleCancel">
        <p>
            <router-link :to="{
                name: 'projectDetail',
                query: {id: projectId, kpiBusinessId: kpiBusinessId, tabActive: 2}
            }" target="_blank">请先建立该项目相关的播主公共分组
            </router-link>
            ，以按需锁定考核指标取数的媒体号范围。
        </p>
        <a-row class="content">
            <a-col>
                <div class="select-box">
                    <base-button :type="'primary'" :title="'刷新分组列表'" @onClickBtn="updataGroupList"
                        style="margin-left: 10px">
                    </base-button>
                </div>
                <div class="checkbox-box">
                    <a-spin v-if="loading" size="large" tip="加载中..." style="padding: 24px" />
                    <a-row style="padding: 8px 0" v-for="(item, index) in groupList" :key="index">
                        <a-col :span="24">
                            <a-checkbox :value="item.authorAccountGroupId" :checked="(authorIds || []).some(v => v.authorGroupId == item.authorAccountGroupId)"
                                @change="onChangeGroup(item)">
                                {{ item.authorAccountGroupName }}
                            </a-checkbox>
                        </a-col>
                    </a-row>
                    <!--</a-checkbox-group>-->
                </div>
            </a-col>
        </a-row>
        <template solt="footer">
            <div class="flex-end">
                <base-button :title="'取消'" @onClickBtn="handleCancel" style="margin-right: 20px">
                </base-button>
                <base-button :type="'primary'" :title="'保存'" :loading="is_submit" :disabled="is_submit"
                    @onClickBtn="handleSubmit">
                </base-button>
            </div>
        </template>
    </a-drawer>
</template>

<script>
import _API from "@/api/projectManagement";

export default {
    name: "createGroupDrawer",
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "添加媒体号到指标",
        },
        projectId: {
            type: [String, Number],
        },
        kpiBusinessId: {
            type: [String, Number],
        },
    },
    data() {
        return {
            groupList: [],
            authorIds: [],
            is_submit: false,
            loading: false,
        };
    },
    mounted() {
        // this.getGroupList();
    },
    methods: {
        getGroupList() {
            this.loading = true;
            _API.getAuthorGroupList({businessId: this.kpiBusinessId}).then((res) => {
                this.loading = false;
                if (res.code == 200) {
                    this.groupList = res.data || [];
                } else {
                    this.$message.error(res.message || "获取分组失败");
                }
            });
        },
        updataGroupList() {
            this.getGroupList();
        },
        onChangeGroup(value) {
            const { authorAccountGroupId, authorAccountGroupName } = value;
            if (this.authorIds.some(v => v.authorGroupId == authorAccountGroupId)) {
                this.authorIds = this.authorIds.filter(v => v.authorGroupId !== authorAccountGroupId);
                return;
            }

            const obj = { authorGroupId: authorAccountGroupId, authorGroupName: authorAccountGroupName };
            this.authorIds.push(obj);
        },
        removeGroup(index) {
            this.authorIds.splice(index, 1);
        },
        handleCancel() {
            this.$emit("handleCancel");
            this.authorIds = [];
            this.groupList = [];
            this.is_submit = false
        },
        handleSubmit() {
            if (this.authorIds.length) {
                this.is_submit = true;
                this.$emit('authorAccountGroupIdList', this.authorIds);
                this.handleCancel()
            } else {
                this.$message.error("请选择分组");
            }
        },
    },
};
</script>

<style scoped lang="less">
.select-box {
    display: flex;
    align-items: center;
}

.line {
    width: 1px;
    background: #c2c2c2;
    height: calc(100vh - 300px);
    margin: 0 8px;
}

.content {
    height: calc(100vh - 300px);
    overflow-y: auto;
}

.groupName {
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 20px 0;
    padding: 40px 0 20px 20%;
    border-top: 1px solid #cccccc
}

.checkbox-box {
    padding: 15px;
    height: calc(100vh - 300px);
    overflow-y: auto;
}
</style>
    