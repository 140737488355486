<template>
    <div>
        <a-drawer
            :visible="visible"
            class="custom-class"
            :title="title"
            placement="right"
            width="80%"
            @close="handleCancel"
        >
            <a-spin :spinning="spinning" size="large" tip="加载中...">
                <a-form-model
                    ref="form"
                    :model="form"
                    :rules="rules"
                    v-bind="layout"
                    style="height:calc(100vh - 200px);overflow-y: auto"
                >
                    <p class="title_text">基础信息</p>

                    <a-form-model-item label="指标大类" prop="normFirstCategory">
                        <a-select
                            v-model="form.normFirstCategory"
                            placeholder="请选择"
                            :disabled="isDisabled"
                            @change="getNormTypeList"
                        >
                            <a-select-option v-for="item in normDataList" :key="item.columnFirstCategory">
                                {{ item.columnFirstCategoryViewName }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item prop="normType">
                        <span slot="label">指标分类
                            <a-tooltip>
                                <template #title>
                                    短视频/直播类指标，在对应类型分组下单独考核（在短视频+直播类分组下也要考核）；播主和经销商类指标在所有分组下都要考核。
                                </template>
                                <a-icon class="mr-8" type="info-circle"/>
                            </a-tooltip>
                        </span>
                        <a-select
                            v-model="form.normType"
                            placeholder="请选择"
                            :disabled="isDisabled"
                            @change="getNormNameList"
                        >
                            <a-select-option v-for="item in normTypeList" :key="item.normType">
                                {{ item.normTypeViewName }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="指标名称" prop="normName">
                        <a-select
                            v-model="form.normName"
                            placeholder="请选择"
                            :disabled="isDisabled"
                            @change="getStatisticTypeList"
                        >
                            <a-select-option v-for="item in normNameList" :key="item.columnName">
                                {{ item.columnCnName }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="指标显示名" prop="normViewName">
                        <a-input
                            v-model="form.normViewName"
                            :maxLength="20"
                            placeholder="请输入"
                            :disabled="isDisabledAll"
                            :suffix="`${(form.normViewName || '').length}/20`"
                        />
                    </a-form-model-item>
                    <a-form-model-item label="备注">
                        <div class="textarea-box">
                            <a-textarea
                                v-model="form.normRemark"
                                :maxLength="200"
                                placeholder="请输入"
                                :disabled="isDisabledAll"
                            />
                            <div class="size">{{ (form.normRemark || '').length }}/200</div>
                        </div>
                    </a-form-model-item>

                    <a-divider />
                    <p class="title_text">统计规则</p>

                    <a-form-model-item label="统计类型" prop="statisticType">
                        <a-select
                            v-model="form.statisticType"
                            placeholder="请选择"
                            :disabled="isDisabledAll"
                        >
                            <a-select-option v-for="item in columnInfoList.statisticTypeArr" :key="item.id">
                                {{ item.label }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>

                    <div v-if="form.normType == 'AWEME' || form.normType == 'LIVE' || form.normType == 'AUTHOR'">
                        <a-divider />
                        <p class="title_text">统计对象<span>{{ filterType() }}筛选需满足以下全部条件</span></p>

                        <a-form-model-item prop="statisticObject.cycleRange" ref="cycleRange" v-if="form.normType == 'AWEME' || form.normType == 'LIVE'">
                            <span slot="label" v-if="form.normType == 'AWEME'">短视频发布日期
                                <a-tooltip>
                                    <template #title>
                                        通过发布日期圈定短视频的取数范围：<br>
                                        ①全周期：取全周期内发布的短视频。<br>
                                        ②当前周期：取当日所在考核周期内发布的短视频。
                                    </template>
                                    <a-icon class="mr-8" type="info-circle"/>
                                </a-tooltip>
                            </span>
                            <span slot="label" v-if="form.normType == 'LIVE'">直播开播日期
                                <a-tooltip>
                                    <template #title>
                                        通过开播日期圈定直播的取数范围。
                                    </template>
                                    <a-icon class="mr-8" type="info-circle"/>
                                </a-tooltip>
                            </span>
                            <a-radio-group v-model="form.statisticObject.cycleRange" :disabled="isDisabledAll">
                                <a-radio v-for="item in columnInfoList.cycleArr" :value="item" :key="item">
                                    {{ item == 'CURRENT' ? '当前周期' : '全周期' }}
                                </a-radio>
                            </a-radio-group>
                        </a-form-model-item>
                        <a-form-model-item :colon="false">
                            <span slot="label">有效性判断
                                <a-tooltip>
                                    <template #title>
                                        <span v-if="form.normType == 'AWEME'">在满足发布日期范围的短视频中，通过有效性条件过滤短视频。</span>
                                        <span v-if="form.normType == 'LIVE'">在满足开播日期范围的直播中，通过有效性条件过滤直播。</span>
                                        <span v-if="form.normType == 'AUTHOR'">通过有效性条件过滤播主。</span>
                                    </template>
                                    <a-icon class="mr-8" type="info-circle"/>
                                </a-tooltip>
                            </span>
                        </a-form-model-item>
                        <a-form-model-item label="平台" prop="statisticObject.platformList">
                            <a-checkbox-group :options="platformList" v-model="form.statisticObject.platformList" disabled />
                        </a-form-model-item>
                        <a-form-model-item label="播主号范围" prop="statisticObject.authorAccountGroupIdList" ref="authorAccountGroupIdList">
                            <div>
                                <div>
                                    <a-radio-group v-model="form.authorGroupId" @change="onChangeAuthor" :disabled="isDisabledAll">
                                        <a-radio :value="1" style="margin-right: 30px;">
                                            指定媒体号
                                            <base-button
                                                :title="'添加'"
                                                :icon="'plus'"
                                                :disabled="form.authorGroupId == 2"
                                                v-if="is_detail != 3"
                                                @onClickBtn="handleAuthorModalShow"
                                            >
                                            </base-button>
                                        </a-radio>
                                        <a-radio :value="2">
                                            所有媒体号
                                        </a-radio>
                                    </a-radio-group>
                                </div>
                                <a-tag color="blue" v-for="(item, index) in (form.statisticObject.authorAccountGroupList || [])" :key="item.authorGroupId" closable @close="delAuthor(item, index)" :disabled="isDisabledAll">{{ item.authorGroupName || '-' }}</a-tag>
                            </div>
                        </a-form-model-item>
                        <a-form-model-item label="筛选条件" v-if="form.normType != 'AUTHOR'">
                            <base-button
                                :title="'清除全部'"
                                v-if="is_detail != 3"
                                @onClickBtn="delCondition('all')"
                            >
                            </base-button>
                            <div>
                                <a-row :gutter="10" type="flex" align="middle" v-for="(item, index) in form.statisticObject.filterCriteriaList" :key="index">
                                    <a-col :span="7">
                                        <a-select
                                            v-model="item.fieldName"
                                            placeholder="请选择"
                                            :disabled="isDisabledAll"
                                            @change="getOperatorList(arguments, item, index)"
                                        >
                                            <a-select-option v-for="m in columnInfoList.filterCriteriaArr" :key="m.fieldName" :list="m.operatorInfoList" :disabled="form.statisticObject.fieldNameList.indexOf(m.fieldName) != '-1'">
                                                {{ m.fieldViewName }}
                                            </a-select-option>
                                        </a-select>
                                    </a-col>
                                    <a-col :span="6">
                                        <a-select
                                            v-model="item.operator"
                                            placeholder="请选择"
                                            :disabled="isDisabledAll"
                                            @change="getOperatorNumberList(arguments, item)"
                                        >
                                            <a-select-option v-for="item in item.operatorInfoList" :key="item.operator" :number="item.operandNum">
                                                {{ filterOperator(item.operator) }}
                                            </a-select-option>
                                        </a-select>
                                    </a-col>
                                    <a-col :span="8" style="display: flex; align-items: center;" v-if="item.operator == 'IS'">
                                        <a-select
                                            v-model="item.operandList[0]"
                                            placeholder="请选择"
                                            :disabled="isDisabledAll"
                                        >
                                            <a-select-option key="true">是</a-select-option>
                                            <a-select-option key="false">否</a-select-option>
                                        </a-select>
                                    </a-col>
                                    <a-col :span="8" style="display: flex; align-items: center;" v-else-if="item.operator == 'LIKE'">
                                        <a-input
                                            v-model="item.operandList[0]"
                                            placeholder="请输入"
                                            class="input"
                                            :disabled="isDisabledAll"
                                        />
                                    </a-col>
                                    <a-col :span="8" style="display: flex; align-items: center;" v-else>
                                        <div class="input-box" v-for="(m, i) in item.operatorNumber" :key="i">
                                            <span v-if="i > 0"> ~ </span>
                                            <a-input-number
                                                v-model="item.operandList[i]"
                                                placeholder="请输入"
                                                class="input"
                                                :min="0"
                                                :disabled="isDisabledAll"
                                            />
                                        </div>
                                    </a-col>
                                    <a-col :span="3" v-if="is_detail != 3">
                                        <ASpace>
                                            <a-icon type="minus-circle" class="icon" @click="delCondition('single', index)" />
                                            <a-icon type="plus-circle" class="icon" @click="addCondition" />
                                        </ASpace>
                                    </a-col>
                                </a-row>
                            </div>
                        </a-form-model-item>
                        <a-form-model-item prop="statisticObject.limitType" ref="limitType" v-if="form.normType != 'AUTHOR'">
                            <span slot="label">取数容量
                                <a-tooltip>
                                    <template #title>
                                        {{ form.normType == 'AWEME' ? '在满足发布日期范围和有效性判断的短视频中，以学员维度聚合并截选指定数量的短视频。' : '在满足开播日期范围和有效性判断的直播中，以学员维度聚合并截选指定数量的直播。' }}
                                    </template>
                                    <a-icon class="mr-8" type="info-circle"/>
                                </a-tooltip>
                            </span>
                            <a-radio-group v-model="form.statisticObject.limitType" :disabled="isDisabledAll" @change="onChangeLimitType">
                                <a-radio v-for="item in columnInfoList.limitTypeArr" :value="item" :key="item">
                                    {{ item == 'ALL' ? '全部' : item == 'TOP' ? '升序前N个' : '降序前N个' }}
                                </a-radio>
                            </a-radio-group>
                        </a-form-model-item>
                        <a-form-model-item label="排序字段" prop="statisticObject.orderField" v-if="form.statisticObject.limitType != 'ALL' && form.normType != 'AUTHOR'">
                            <a-select
                                v-model="form.statisticObject.orderField"
                                placeholder="请选择排序字段"
                                :disabled="isDisabledAll"
                            >
                                <a-select-option v-for="item in columnInfoList.orderColumnArr" :key="item.name">
                                    {{ item.viewName }}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                        <a-form-model-item label="N=" prop="statisticObject.limitCount" v-if="form.statisticObject.limitType != 'ALL' && form.normType != 'AUTHOR'">
                            <a-input-number
                                v-model="form.statisticObject.limitCount"
                                placeholder="请输入1-100"
                                class="input"
                                :min="0"
                                :max="100"
                                :disabled="isDisabledAll"
                                style="width: 150px;"
                            />
                        </a-form-model-item>
                    </div>

                </a-form-model>
            </a-spin>

            <template solt="footer" v-if="is_detail != 3">
                <a-row style="padding-top: 40px; border-top: 1px solid #eee;">
                    <a-col :span="20">
                        <span>提示：变更指标内容将影响KPI结果统计，请谨慎操作！<br>12点前更新，当日生效；12点后更新，次日生效。</span>
                    </a-col>
                    <a-col :span="4">
                        <ASpace>
                        <base-button
                            :title="'取消'"
                            @onClickBtn="handleCancel"
                        >
                        </base-button>
                        <base-button
                            :type="'primary'"
                            :title="'确定'"
                            :loading="isSubmit"
                            :disabled="isSubmit"
                            @onClickBtn="handleSubmit"
                        >
                        </base-button>
                        </ASpace>
                    </a-col>
                </a-row>
            </template>
        </a-drawer>

        <!-- 添加媒体号 -->
        <addAuthorModal
            ref="addAuthorModal"
            :visible="addAuthorModal"
            :project-id="projectId"
            :kpiBusinessId="kpiBusinessId"
            @handleCancel="handleAuthorModalCancel"
            @authorAccountGroupIdList="authorAccountGroupIdList"/>
    </div>
</template>

<script>
import _API from "@/api/projectManagement";
import addAuthorModal from './addAuthorModal';
import { cloneDeep, keys } from "lodash-es";

const stringToObject = (data) => {
    try {
        return JSON.parse(data);
    } catch(e) {
        return {};
    }
};

const filterCriteriaList = { fieldName: undefined, operator: undefined, operandList: [], operatorInfoList: [], operatorNumber: 1 }

const statisticObject = {
    cycleRange: undefined,
    platformList: ['DOUYIN', 'KUAISHOU'],
    authorAccountGroupList: [],
    authorAccountGroupIdList: [],
    filterCriteriaList: [cloneDeep(filterCriteriaList)],
    fieldNameList: [],
    limitType: undefined,
    orderField: undefined,
    limitCount: undefined,
}

const columnInfoList = {
    statisticTypeArr: [],
    cycleRangeList: [],
    filterCriteriaArr: [],
    limitTypeArr: [],
    orderColumnArr: [],
}

export default {
    name: 'indexDrawer',
    components: {
        addAuthorModal
    },
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "添加指标",
        },
        getRadioValue: {
            type: [String, Number],
        },
        project_data: {
            type: String,
            default: "",
        },
        assessmentNormId: {
            type: [String, Number],
        },
        kpiBusinessId: {
            type: [String, Number],
        },
        is_detail: {
            type: Number,
            default: 1,
        },
        projectId: {
            type: [String, Number],
        },
    },
    watch: {
        project_data: {
            handler() {
                this.project = stringToObject(this.project_data);
            },
            deep: true,
            immediate: true,
        },
        visible: {
            async handler() {
                if(!this.visible) return;

                await this.getNormMetaList()

                
                if(this.is_detail == 2) {
                    this.isDisabled = true
                    this.isDisabledAll = false
                } else if(this.is_detail == 3) {
                    this.isDisabled = true
                    this.isDisabledAll = true
                } else {
                    this.isDisabled = false
                    this.isDisabledAll = false

                    this.form.normFirstCategory = this.getRadioValue
                    this.getNormTypeList()
                    
                    if(this.getRadioValue == 'CLUE') {
                        this.form.normType = 'DEALER'
                    } else {
                        this.form.normType = 'AWEME'
                    }
                    this.getNormNameList()
                }
            }
        },
    },
    data() {
        let checkAuthorIdList = (rule, value, callback) => {
            if (this.form.authorGroupId != 2 && value.length < 1) {
                callback(new Error('请选择播主号范围'))
            } else {
                callback();
            }
        }
        
        return {
            form: {
                businessId: this.kpiBusinessId,
                normFirstCategory: undefined,
                normType: undefined,
                normName: undefined,
                normMetadataId: undefined,
                normViewName: undefined,
                normRemark: undefined,
                statisticType: undefined,
                authorGroupId: 1,
                statisticObject: cloneDeep(statisticObject),
            },
            layout: {
                labelCol: { span: 4 },
                wrapperCol: { span: 15 }
            },
            rules: {
                normFirstCategory: [
                    { required: true, message: '请选择指标大类', trigger: 'change' }
                ],
                normType: [
                    { required: true, message: '请选择指标分类', trigger: 'change' }
                ],
                normName: [
                    { required: true, message: '请选择指标名称', trigger: 'change' }
                ],
                normViewName: [
                    { required: true, message: '请填写指标显示名', trigger: 'change' }
                ],
                statisticType: [
                    { required: true, message: '请选择统计类型', trigger: 'change' }
                ],
                'statisticObject.cycleRange': [
                    { required: true, message: '请选择周期', trigger: 'change' }
                ],
                'statisticObject.platformList': [
                    { required: true, message: '请选择平台', trigger: 'change' }
                ],
                'statisticObject.authorAccountGroupIdList': [
                    // { required: true, message: '请选择播主号范围', trigger: 'change' }
                    { required: true, validator: checkAuthorIdList, trigger: 'change' }
                ],
                'statisticObject.limitType': [
                    { required: true, message: '请选择取数容量', trigger: 'change' }
                ],
                'statisticObject.orderField': [
                    { required: true, message: '请选择排序字段', trigger: 'change' }
                ],
                'statisticObject.limitCount': [
                    { required: true, message: '请输入1-100', trigger: 'change' }
                ],
            },
            isDisabled: false,
            isSubmit: false,
            normDataList: [],
            normTypeList: [],
            normNameList: [],
            columnInfoList: cloneDeep(columnInfoList),
            platformList: [
                { label: '抖音', value: 'DOUYIN' },
                { label: '快手', value: 'KUAISHOU' },
                // { label: '懂车帝', value: 'DONGCHEDI' },
            ],
            aggList: [],
            addAuthorModal: false,
            isDisabledAll: false,
            spinning: false,
        }
    },
    created() {}, 
    mounted() {},
    methods: {
        handleCancel() {
            this.form = {
                businessId: this.kpiBusinessId,
                normFirstCategory: undefined,
                normType: undefined,
                normName: undefined,
                normMetadataId: undefined,
                normViewName: undefined,
                normRemark: undefined,
                statisticType: undefined,
                authorGroupId: 1,
                statisticObject: cloneDeep(statisticObject),
            }
            this.$refs.form.clearValidate()
            this.$emit('handleCancel')
        },
        handleSubmit() {
            this.$refs.form.validate((valid) => {
                if(valid) {
                    this.isSubmit = true

                    let _form = this.form
                    if(this.form.normType == 'DEALER' && _form.statisticObject) {
                        delete _form.statisticObject
                    }

                    let filterCriteriaList = (_form.statisticObject || {}).filterCriteriaList
                    if(filterCriteriaList) {
                        for(let i = filterCriteriaList.length - 1; i >= 0; i--) {
                            if(!filterCriteriaList[i].fieldName) {
                                filterCriteriaList.splice(i, 1)
                            }
                        }
                    }
                    
                    if(this.form.normType == 'AUTHOR' && _form.statisticObject.cycleRange) {
                        delete _form.statisticObject.cycleRange
                        delete _form.statisticObject.limitType
                        delete _form.statisticObject.filterCriteriaList
                    }

                    console.log({_form})

                    let body = {
                        method: this.assessmentNormId ? 'put' : 'post',
                        data: _form 
                    }

                    _API.submitPmsProjectBusinessNorm(body).then(res => {
                        this.isSubmit = false

                        if(res.code == 200) {
                            this.handleCancel()
                            this.$message.success('提交成功')
                        } else {
                            this.$message.error(`提交失败，${res.message}`)
                        }
                    })
                }
            })
        },
        //获取详情
        async getPmsProjectBusinessNormDetail() {
            await _API.getPmsProjectBusinessNormDetail(this.assessmentNormId).then(res => {
                this.spinning = false

                if(res.code == 200) {
                    let _data = res.data
                    _data.id = this.assessmentNormId

                    this.form = {
                        ...this.form,
                        ..._data
                    }

                    this.normTypeList = (_.find(this.normDataList, ['columnFirstCategory', this.form.normFirstCategory]) || {}).normTypeInfoList || []

                    this.normNameList = (_.find(this.normTypeList, ['normType', this.form.normType]) || {}).columnInfoList || []

                    let obj = _.find(this.normNameList, ['columnName', this.form.normName]) || {}

                    //统计类型
                    let statisticTypeArr = obj.statisticTypeArr || []
                    this.columnInfoList.statisticTypeArr = this.setStatisticTypeList(statisticTypeArr)

                    //周期
                    this.columnInfoList.cycleArr = obj.cycleArr || []

                    //取数容量
                    this.columnInfoList.limitTypeArr = obj.limitTypeArr || []

                    //排序字段
                    this.columnInfoList.orderColumnArr = obj.orderColumnArr || []

                    //播主号范围
                    this.form.authorGroupId = _data.statisticObject.authorAccountGroupIdList.length > 0 ? 1 : 2

                    //筛选条件
                    this.columnInfoList.filterCriteriaArr = obj.filterCriteriaArr || []
                    if(_data.statisticObject.filterCriteriaList && _data.statisticObject.filterCriteriaList.length > 0) {
                        this.form.statisticObject.fieldNameList = _data.statisticObject.filterCriteriaList.map(item => {
                            return item.fieldName
                        })
                        
                        let filterCriteriaList = []
                        filterCriteriaList = _data.statisticObject.filterCriteriaList.map(item => {
                            return {
                                operatorInfoList: _.find(this.columnInfoList.filterCriteriaArr, ['fieldName', item.fieldName]).operatorInfoList || [],
                                operatorNumber: item.operandList.length || 1
                            }
                        })

                        this.form.statisticObject.filterCriteriaList = _.merge(this.form.statisticObject.filterCriteriaList, filterCriteriaList)
                    } else {
                        this.form.statisticObject.filterCriteriaList = [cloneDeep(filterCriteriaList)]
                        this.form.statisticObject.fieldNameList = []
                    }
                } else {
                    this.$message.error(`获取详情失败，${res.message}`)
                }
            })
        },
        // 获取指标元数据
        getNormMetaList() {
            return new Promise((resolve, reject) => {_API.getNormMetaList().then(res => {
                    if(res.code == 200) {
                        this.normDataList = res.data

                        if(this.assessmentNormId) {
                            this.spinning = true
                            this.getPmsProjectBusinessNormDetail()
                        }

                        resolve()
                    } else {
                        this.$message.error(`获取指标元数据列表失败，${res.message}`)
                    }
                })
            })
        },
        // 获取指标元数据 - 指标分类
        getNormTypeList() {
            //重置数据
            this.form.normType = undefined
            this.form.normName = undefined
            this.form.statisticType = undefined
            this.form.statisticObject = cloneDeep(statisticObject)
            this.normNameList = []
            this.columnInfoList = cloneDeep(columnInfoList)
            
            this.normTypeList = (_.find(this.normDataList, ['columnFirstCategory', this.form.normFirstCategory]) || {}).normTypeInfoList || []
        },
        // 获取指标元数据 - 指标名称
        getNormNameList() {
            //重置数据
            this.form.normName = undefined
            this.form.statisticType = undefined
            this.form.statisticObject = cloneDeep(statisticObject)
            this.columnInfoList = cloneDeep(columnInfoList)
            
            this.normNameList = (_.find(this.normTypeList, ['normType', this.form.normType]) || {}).columnInfoList || []
        },
        // 获取统计类型、统计对象数据
        getStatisticTypeList() {
            //重置数据
            this.form.statisticType = undefined
            this.form.statisticObject = cloneDeep(statisticObject)
            this.columnInfoList = cloneDeep(columnInfoList)

            let obj = _.find(this.normNameList, ['columnName', this.form.normName]) || {}

            // 设置normMetadataId
            this.form.normMetadataId = obj.normMetadataId

            //统计类型
            let statisticTypeArr = obj.statisticTypeArr || []
            this.columnInfoList.statisticTypeArr = this.setStatisticTypeList(statisticTypeArr)
            // this.form.statisticType = statisticTypeArr.indexOf('SUM') == '-1' ? undefined : 'SUM'
            if(statisticTypeArr.indexOf('SUM') != -1) {
                this.form.statisticType = 'SUM'
            } else if(statisticTypeArr.indexOf('COMPUTE') != -1) {
                this.form.statisticType = 'COMPUTE'
            } else if(statisticTypeArr.indexOf('COUNT') != -1) {
                this.form.statisticType = 'COUNT'
            }

            let checkArr = ['AWEME', 'LIVE'] //需要验证统计对象的类型
            //周期
            this.columnInfoList.cycleArr = obj.cycleArr || []
            this.form.statisticObject.cycleRange = 'CURRENT'
            checkArr.includes(this.form.normType) ? this.$refs.cycleRange.onFieldChange() : ''

            //取数容量
            this.columnInfoList.limitTypeArr = obj.limitTypeArr || []
            this.form.statisticObject.limitType = 'ALL'
            checkArr.includes(this.form.normType) ? this.$refs.limitType.onFieldChange() : ''

            //排序字段
            this.columnInfoList.orderColumnArr = obj.orderColumnArr || []

            //筛选条件
            this.columnInfoList.filterCriteriaArr = obj.filterCriteriaArr || []
        },
        // 设置统计类型数据
        setStatisticTypeList(arr) {
            let list = []
            arr.map(item => {
                list.push({
                    id: item,
                    label: this.filterStatisticType(item)
                })
            })
            return list
        },
        // 筛选统计类型
        filterStatisticType(type) {
            let txt = ''
            switch(type) {
                case 'SUM':
                    txt = '求和'
                    break;
                case 'COUNT':
                    txt = '计数'
                    break;
                case 'AVG':
                    txt = '均值'
                    break;
                case 'COMPUTE':
                    txt = '计算'
                    break;
            }
            return txt
        },
        // 筛选指标分类类型
        filterType(val) {
            let txt = ''
            switch(this.form.normType) {
                case 'AWEME':
                    txt = '短视频'
                    break;
                case 'LIVE':
                    txt = '直播'
                    break;
                case 'AUTHOR':
                    txt = '播主'
                    break;
                default:
                    txt = '-'
                    break;
            }
            return txt
        },
        // 筛选 - 筛选条件
        filterOperator(val) {
            let txt = ''
            switch(val) {
                case 'LIKE':
                    txt = '包含'
                    break;
                case 'BETWEEN':
                    txt = '介于'
                    break;
                case 'IS':
                    txt = '='
                    break;
                default:
                    txt = '-'
                    break;
            }
            return txt
        },
        // 设置 - 筛选条件状态
        setOperatorStatus(val) {
            return this.columnInfoList.filterCriteriaArr.some(item => item.fieldName == val)
        },
        //修改媒体号类型
        onChangeAuthor() {
            if(this.form.authorGroupId == 2) {
                this.form.statisticObject.authorAccountGroupList = []
                this.form.statisticObject.authorAccountGroupIdList = []
            }
        },
        //打开媒体号抽屉
        handleAuthorModalShow() {
            this.addAuthorModal = true
        },
        //关闭媒体号抽屉
        handleAuthorModalCancel() {
            this.addAuthorModal = false
        },
        //获取媒体号
        authorAccountGroupIdList(ids) {
            this.form.statisticObject.authorAccountGroupList = ids || []
            this.form.statisticObject.authorAccountGroupIdList = ids.map(item => {
                return item.authorGroupId
            })
            this.$refs.authorAccountGroupIdList.onFieldChange()
        },
        //删除媒体号
        delAuthor(item, index) {
            this.form.statisticObject.authorAccountGroupList.splice(index, 1)
            this.form.statisticObject.authorAccountGroupIdList = this.form.statisticObject.authorAccountGroupList.map(item => {
                return item.authorGroupId
            })
            this.$refs.authorAccountGroupIdList.onFieldChange()
        },
        // 删除筛选条件
        delCondition(type, index) {
            if(type == 'all') {
                this.form.statisticObject.filterCriteriaList = [cloneDeep(filterCriteriaList)]
                this.form.statisticObject.fieldNameList = []
            } else {
                if(this.form.statisticObject.filterCriteriaList.length == 1) {
                    this.form.statisticObject.filterCriteriaList = [cloneDeep(filterCriteriaList)]
                    this.form.statisticObject.fieldNameList = []
                } else {
                    this.form.statisticObject.filterCriteriaList.splice(index, 1)
                    this.form.statisticObject.fieldNameList.splice(index, 1)
                }
            }
        },
        // 添加筛选条件
        addCondition() {
            this.form.statisticObject.filterCriteriaList.push({
                fieldName: undefined, operator: undefined, operandList: [], operatorInfoList: [], operatorNumber: 1
            })
        },
        //获取筛选方式
        getOperatorList(e, item, index) {
            let obj = { operator: undefined, operatorInfoList: e[1].data.attrs.list || [], operandList: [], operatorNumber: 1 }
            this.$set(item, Object.assign(item, obj))
            this.form.statisticObject.fieldNameList[index] = e[0]
        },
        //获取筛选输入框个数
        getOperatorNumberList(e, item) {
            let obj = { operandList: [], operatorNumber: e[1].data.attrs.number || 1 }
            this.$set(item, Object.assign(item, obj))
        },
        onChangeLimitType() {
            if(this.form.statisticObject.limitType == 'ALL') {
                this.form.statisticObject.orderField = undefined
                this.form.statisticObject.limitCount = ''
            }
        },
    }
}
</script>

<style lang="less" scoped>
.title_text {
    font-size: 16px;
    font-weight: bold;
    span {
        padding-left: 15px;
        font-size: 12px;
        font-weight: normal;
    }
}

.icon {
    font-size: 20px;
    color: #1890ff;
    cursor: pointer;
}
.input-box {
    flex: 1;
    display: flex;
    align-items: center;
    .input {
        flex: 1;
    }
}
/deep/ .textarea-box {
    position: relative;
    .ant-input {
        padding-bottom: 20px;
    }
    .size {
        position: absolute;
        bottom: 5px;
        left: 5px;
        right: 22px;
        line-height: 1.5;
        background: #fff;
        text-align: right;
    }
}
</style>