<template>
  <div class="box">
    <h2>考核指标</h2>
    <a-row>
      <a-col :span="13">
        <a-radio-group v-model="getRadioValue" button-style="solid" @change="changeRadioValue">
          <a-radio-button value="MEDIA">媒体传播</a-radio-button>
          <a-radio-button value="CLUE">线索成交</a-radio-button>
        </a-radio-group>
      </a-col>
      <a-col :span="11">
        <!--v-if="addBaseOrg"-->
        <!--:disabled="!isT5"-->
        <ASpace class="flex-end">
          <a-button
              type="primary"
              icon="plus"
              :disabled="btnStatus"
              @click="showIndexDrawer('add')">
            添加指标
          </a-button>
          <a-button
              type="primary"
              icon="clock-circle"
              @click="showTranslateModal">
              延时统计
          </a-button>
          <a-button
              type="primary"
              icon="hdd"
              @click="showTotalModal">
              最近产出统计
          </a-button>
        </ASpace>
      </a-col>
    </a-row>

    <div class="data-table">
      <a-table
          :loading="loading"
          :columns="columns"
          :data-source="list"
          :row-key="(record, index) => index"
          :pagination="false"
          :scroll="{ x: 1000}"
      >
        <div slot="name" slot-scope="text, record">
          <a href="#" @click="showIndexDrawer('detail',record)">{{ record.normViewName || "-" }}</a>
        </div>
        <div slot="groupType" slot-scope="text, record">
          <!-- <span>{{ record.normType == 'AWEME' ? "短视频" : record.normType == 'LIVE' ? "直播" : "经销商" }}</span> -->
          <span v-if="record.normType == 'AWEME'">短视频</span>
          <span v-else-if=" record.normType == 'LIVE'">直播</span>
          <span v-else-if=" record.normType == 'DEALER'">经销商</span>
          <span v-else-if=" record.normType == 'AUTHOR'">播主</span>
          <span v-else>-</span>
        </div>
        <div slot="KPI" slot-scope="text, record">
          {{ record.isResolve ? '已分解' : '未分解' }}
        </div>
        <div slot="ObjectDecomposition" slot-scope="record">
          <div class="title-span">
            <span>100</span>
            <span>500</span>
            <span>200</span>
            <span>300</span>
            <span>1000</span>
          </div>
        </div>
        <div slot="activityTime" slot-scope="text, record">
          <div class="detail-item">
            <span class="detail-item__label">更新时间：</span>
            <span class="detail-item__value">{{ record.atime || "-" }}</span>
          </div>
          <div class="detail-item">
            <span class="detail-item__label">更新人：</span>
            <span class="detail-item__value">{{ record.actor || "-" }}</span>
          </div>
        </div>
        <div slot="operation" slot-scope="record">
          <!--<a-button type="link" @click="showIndexDrawer('edit',record)"-->
          <!--          v-if="getTemporaryParameters.projectStatus != 3"-->
          <!--          :disabled="!isT5">编辑-->
          <!--</a-button>-->
          <!--<a-button type="link" @click="showIndexDrawer('del',record)"-->
          <!--          v-if="getTemporaryParameters.projectStatus != 3"-->
          <!--          :disabled="!isT5 ">删除-->
          <!--</a-button> -->
          <a-button type="link" @click="showIndexDrawer('edit',record)" :disabled="btnStatus">编辑
          </a-button>
          <a-button type="link" @click="showIndexDrawer('objective', record)" :disabled="btnStatus">目标分解
          </a-button>
          <a-button type="link" @click="showIndexDrawer('del',record)" :disabled="btnStatus">删除
          </a-button>
        </div>
      </a-table>
      <base-pagination
          :current-page="pagination.current"
          :page-size="pagination.pageSize"
          :total="pagination.total"
          @onChange="handlePaginationChange"
          @onShowSizeChange="handlePaginationChange"
      />
    </div>


    <!--设置考核周期-->
    <assessmentCycleDrawer
        :visible="assessmentCycleVisible"
        :cycle-list="cycleList"
        :project-id="projectId"
        @handleCancel="handleCancel"
        :project_data="JSON.stringify(getTemporaryParameters)"
        :btn-status="btnStatus"
        @saveCycles="getCycleList"
    />

    <!--指标抽屉-->
    <indexDrawer
        ref="indexDrawer"
        :visible="indexDrawer"
        :title="indexDrawer_title"
        :get-radio-value="getRadioValue"
        :project-id="projectId"
        :project_data="JSON.stringify(getTemporaryParameters)"
        :assessment-norm-id="assessmentNormId"
        :kpi-business-id="kpiBusinessId"
        :is_detail="is_detail"
        @handleCancel="handleIndexDrawerCancel"/>

    <!-- 目标分解 -->
    <objectiveDrawer
        ref="objectiveDrawer"
        :visible="objectiveDrawer"
        :title="objectiveDrawer_title"
        :project-id="projectId"
        :kpi-business-id="kpiBusinessId"
        :get-radio-value="getRadioValue"
        :assessment-norm-id="assessmentNormId"
        :is_detail="is_detail"
        :project_data="JSON.stringify(getTemporaryParameters)"
        @handleCancel="handleObjectiveDrawerCancel"/>

    <!-- 延时统计 -->
    <translateModal
      ref="translateModal"
      :visible="translateModal"
      :title="'指标延时统计'"
      :project-id="projectId"
      :btn-status="btnStatus"
      @handleCancel="handleTranslateModalCancel"/>

    <!-- 最近产出统计 -->
    <totalModal
      ref="totalModal"
      :visible="totalModal"
      :project-id="projectId"
      :btn-status="btnStatus"
      @handleCancel="handleTotalModalCancel"/>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { cloneDeep, isEmpty, isEqual } from "lodash-es";
import moment from "moment/moment";
import assessmentCycleDrawer from "../components/assessmentCycleDrawer";
import indexDrawer from "../components/indexDrawer";
import _API from "@/api/projectManagement";
import objectiveDrawer from '../components/objectiveDrawer'
import translateModal from '../components/translateModal'
import totalModal from '../components/totalModal'

const {mapGetters} = createNamespacedHelpers("temporary_parameters");
const columns = [
  {
    title: "指标显示名",
    dataIndex: "",
    scopedSlots: {customRender: "name"},
    width: 180,
  },
  {
    title: "指标分类",
    scopedSlots: {customRender: "groupType"},
    width: 180,
  },
  {
    title: "考核目标",
    // dataIndex: "KPI",
    scopedSlots: {customRender: "KPI"},
    // children: [],
  },
  {
    title: "更新信息",
    scopedSlots: {customRender: "activityTime"},
    width: 260,
  },
  {
    title: "操作",
    scopedSlots: {customRender: "operation"},
    width: 160,
  },
];
export default {
  name: "assessmentIndex",
  components: {assessmentCycleDrawer, indexDrawer, objectiveDrawer, translateModal, totalModal},
  props: {
    radioValue: {
      type: [Number, String],
      default: 1,
    },
    assessmentCycle_list: {
      type: Array,
      default: () => [],
    },
    projectId: {
      type: [String, Number],
    },
    kpiBusinessId: {
      type: [String, Number],
    },
    btnStatus: {
      type: Boolean,
      default: false
    },
  },
  // watch: {
  //   assessmentCycle_list: {
  //     handler(val) {
  //       this.assessmentCycleList = val
  //     },
  //     deep: true,
  //     immediate: true,
  //   },
  // },
  data() {
    return {
      getRadioValue: 'MEDIA',
      loading: false,
      columns: columns,
      list: [{
        age: "1111",
        age1: "22",
        age2: "222",
        age3: "333",
        age4: "444",
      }],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      indexDrawer: false,
      addBaseOrg: false,
      assessmentCycleVisible: false,
      isT5: false,
      cycleList: [],
      indexDrawer_title: "添加指标",
      assessmentNormId: "",
      is_detail: 1,
      assessmentCycleOptionArray: [],
      objectiveDrawer: false,
      objectiveDrawer_title: '考核目标分解',
      translateModal: false,
      assessmentCycleList: [],
      totalModal: false,
    };
  },
  mounted() {
    this.getKpiAssessmentNormList();
    this.getCycleList()
    // this.getFollowerOptions();
  },
  methods: {
    getFollowerOptions() {
      _API.getFollowerOptions({businessId: this.getTemporaryParameters.kpiBusinessId || this.kpiBusinessId}).then(res => {
        if(res.code == 200) {
          this.assessmentCycleOptionArray = res.data.assessmentCycleOptionArray;
        } else {
          this.$message.error(res.message || "获取失败");
        }
      });
    },
    getKpiAssessmentNormList() {
      this.loading = true;
      _API.getPmsProjectBusinessNorm(
          {
            page: this.pagination.current,
            size: this.pagination.pageSize,
            // normType: this.getRadioValue,
            normFirstCategory: this.getRadioValue,
            projectId: this.getTemporaryParameters.projectId || this.$route.query.projectId,
            // kpiBusinessId: this.getTemporaryParameters.kpiBusinessId || this.$route.query.kpiBusinessId,
          }).then((res) => {
        if(res.code == 200) {
          // this.handleTableColumns(res.data?.list || []);
          this.list = res.data?.list || []
          this.pagination.total = res.data.total || 0;
        } else {
          this.list = [];
          this.pagination.total = 0;
          this.$message.error(res.message || "获取列表数据失败");
        }
        this.loading = false;
      });
    },
    handleTableColumns(list) {
      let titles = [];
      const newList = list.map(item => {
        const obj = {};

        const {normCycleTargetList} = item;

        if(!isEmpty(normCycleTargetList)) {
          if(titles.length <= normCycleTargetList.length) {
            titles = normCycleTargetList;
          }
          normCycleTargetList.forEach((child, index) => {
            obj[`__period${ index }__`] = child;
          });
        }

        return {...item, ...obj};
      });

      this.columns = this.columns.map(item => {
        if(isEqual(item.dataIndex, "KPI")) {
          item.children = titles.map((v, index) => ({
            title: v.cycleName,
            dataIndex: `__period${ index }__.incrementTarget`,
            key: `__period${ index }__.incrementTarget`,
            width: 90,
            customRender: (text, record) => {
              // console.log(text, record);
              return (
                  <div>
                    { text || "不考核" }
                  </div>
              );
            },
          }));
        }
        return item;
      });

      this.list = newList || [];
    },

    changeRadioValue() {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getKpiAssessmentNormList();
    },
    handlePaginationChange(page, size) {
      this.pagination.current = page;
      this.pagination.pageSize = size;
      this.getKpiAssessmentNormList();
    },
    showIndexDrawer(type, record) {
      let that = this;
      switch(type) {
        case "add":
          if(that.assessmentCycleList.length == 0 && this.assessmentCycleOptionArray.length == 0) {
            that.$confirm({
              title: "提示",
              content: "请先设置考核周期再添加指标",
              okText: "设置考核周期",
              cancelText: "取消",
              onOk() {
                that.showAssessmentCycleVisible();
              },
            });
            return false;
          }
          that.assessmentNormId = "";
          that.indexDrawer_title = "添加指标";
          that.indexDrawer = true;
          that.kpiBusinessId = this.getTemporaryParameters.kpiBusinessId;
          that.is_detail = 1;
          break;
        case "edit":
          // console.log("编辑");
          that.indexDrawer = true;
          that.assessmentNormId = record.id;
          that.is_detail = 2;
          that.indexDrawer_title = "编辑指标";
          that.kpiBusinessId = this.getTemporaryParameters.kpiBusinessId;
          break;
        case "detail":
          // console.log("详情");
          that.indexDrawer = true;
          that.assessmentNormId = record.id;
          that.is_detail = 3;
          that.indexDrawer_title = "指标详情";
          that.kpiBusinessId = this.getTemporaryParameters.kpiBusinessId;
          break;
        case "del":
          that.$confirm({
            title: "删除指标后，该指标在所有考核周期都不再纳入考核也不再统计。确定删除？",
            okText: "删除",
            cancelText: "取消",
            onOk() {
              // console.log("删除");
              _API.deletePmsProjectBusinessNormDetail(record.id).then(res => {
                if(res.code == 200) {
                  that.$message.success("操作成功");
                  that.getKpiAssessmentNormList();
                } else {
                  that.$message.error("操作失败");
                }
              });
            },

          });
          break;
        case 'objective':
          that.objectiveDrawer = true
          that.assessmentNormId = record.id;
          that.kpiBusinessId = this.getTemporaryParameters.kpiBusinessId;
          break;
      }
    },
    showAssessmentCycleVisible() {
      if(this.assessmentCycleList.length || this.assessmentCycleOptionArray.length) {
        (this.assessmentCycleList || this.assessmentCycleOptionArray).forEach((item) => {
          if(item.isAllCycle == 0) {
            this.cycleList.push(cloneDeep(item));
          }
        });
      } else {
        for(let i = 0; i < 3; i++) {
          let obj = {
            cycleName: `第${ i + 1 }周期`,
            startDate: "",
            endDate: "",
            days: 0,
            businessId: this.getTemporaryParameters.kpiBusinessId,
            isAllCycle: 0,
          };
          this.cycleList.push(obj);
        }
      }
      this.assessmentCycleVisible = true;
    },
    handleCancel() {
      this.cycleList = [];
      this.assessmentCycleVisible = false;
      // this.getFollowerOptions();
      this.getKpiAssessmentNormList();
    },
    handleIndexDrawerCancel() {
      this.indexDrawer = false;
      this.getKpiAssessmentNormList();
    },
    handleObjectiveDrawerCancel() {
      this.objectiveDrawer = false;
      this.getKpiAssessmentNormList();
    },
    showTranslateModal() {
      this.translateModal = true
    },
    handleTranslateModalCancel() {
      this.translateModal = false
    },
    showTotalModal() {
      this.totalModal = true
    },
    handleTotalModalCancel() {
      this.totalModal = false
      this.getKpiAssessmentNormList();
    },
    getCycleList() {
      _API.getCycleList({kpiBusinessId: this.kpiBusinessId}).then((res) => {
        if(res.code == 200) {
          let _data = res.data
          this.assessmentCycleList = _data

          let endDate = ((_data.filter(item => item.isAllCycle == 1))[0] || {}).endDate
          if(_data.length > 0) {
            this.$emit("getCycleList", endDate, _data);
          }
        } else {
          this.$message.error(res.message || "获取考核周期列表失败");
        }
      })
    },
  },
  computed: {
    ...mapGetters(["getTemporaryParameters"]),
    getAddBaseOrg() {
      const assessmentCycleOptionArray = this.assessmentCycleOptionArray;
      // console.log(assessmentCycleOptionArray);
      // 全周期
      const {endDate} = assessmentCycleOptionArray.find(v => isEqual(v.isAllCycle, 1)) || {};
      // console.log(endDate, "全周期");
      // 当天日期
      let date = moment(new Date()).startOf("date"); // .format("YYYY-MM-DD");
      // 全周期 +5
      // 全周期日期
      // 全周期+5
      let date5 = endDate && moment(cloneDeep(endDate)).add(6, "days").startOf("date") || null; //.format("YYYY-MM-DD");
      // console.log(moment(date5).format("YYYY-MM-DD"), "全周期+5");
      // 当前处于全周期T+5内
      // console.log(date5);
      const isT5 = date < date5;  // && isHistory;
      this.isT5 = date < date5;
      // 有一个周期
      this.addBaseOrg = this.getTemporaryParameters.projectStatus == 2 || (this.getTemporaryParameters.projectStatus != 3 && assessmentCycleOptionArray.length == 0) || (this.getTemporaryParameters.projectStatus == 1 && isT5);

      return this.addBaseOrg;
    }
  },
};
</script>

<style scoped lang="less">
.box {
  padding: 10px;
}

.detail-item {
  display: flex;
  font-weight: bold;

  &__value {
    flex: 1;
    font-weight: 400;
  }
}

.data-table {
  margin-top: 30px;

  .title-box {
    text-align: center;
  }

  .title-span {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    span {
      font-weight: 400;
      color: #8b8e92;
      padding: 0 8px;
      margin-top: 8px;
    }
  }
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
</style>
