<template>
  <div>
    <detail_header :id="id" :is-show="false" />

    <a-card style="margin-top: 40px">
      <a-row>
        <a-col :span="2">
          <h3>KPI考核设置</h3>
        </a-col>
        <a-col :span="12">
          <p>
            请设置考核周期、创建考核指标，并将考核目标分解到各周期以生成学员KPI。
          </p>
          <p style="color: orange; line-height: 0">
            <a-icon
              type="info-circle"
              theme="filled"
              style="padding-right: 8px"
            />
            请在{{
              endTime
            }}之前完成KPI考核设置及调整（如需），此日期后不可变更。
          </p>
        </a-col>
      </a-row>
      <div class="tabs_CONTENT">
        <a-tabs
          v-model="activeKey"
          tab-position="left"
          animated
          size="large"
          @change="dataIndex = new Date().getTime()"
        >
          <a-tab-pane key="1" tab="考核周期">
            <assessmentCyclePage
              :project-id="id"
              :kpi-business-id="kpiBusinessId"
              @getCycleList="getCycleList"
              :key="dataIndex"
              :btn-status="btnStatus"
            />
          </a-tab-pane>
          <a-tab-pane key="2" tab="考核指标">
            <assessmentIndex
              :radio-value="groupType"
              :assessment-cycle_list="assessmentCycle_list"
              :kpi-business-id="kpiBusinessId"
              :key="dataIndex"
              :project-id="id"
              :btn-status="btnStatus"
              @getCycleList="getCycleList"
            />
          </a-tab-pane>
          <a-tab-pane key="3" tab="学员KPI">
            <studentKPIPage
              :project-id="id"
              :kpi-business-id="kpiBusinessId"
              :btn-status="btnStatus"
              :key="dataIndex"
            />
          </a-tab-pane>
        </a-tabs>
      </div>
    </a-card>
  </div>
</template>

<script>
import assessmentCyclePage from "./pages/assessmentCyclePage";
import assessmentIndex from "./pages/assessmentIndex";
import studentKPIPage from "./pages/studentKPIPage";
import detail_header from "./components/detail_header";
import { createNamespacedHelpers } from "vuex";
import _API from "@/api/projectManagement";

export default {
  name: "KPIAssessmentSetting",
  components: {
    assessmentCyclePage,
    detail_header,
    assessmentIndex,
    studentKPIPage,
  },
  data() {
    return {
      activeKey: this.$route.query.activeKey || "1",
      id: this.$route.query.projectId || "",
      kpiBusinessId: this.$route.query.kpiBusinessId || "",
      groupType: this.$route.query.groupType || 1,
      assessmentCycle_list: [],
      dataIndex: new Date().getTime(),
      btnStatus: false,
      allCycleTime: "",
      endTime: "",
      delayDay: "",
      project_data: {},
    };
  },
  async mounted() {
    // console.log(this.$route.query.projectId);
    // if(this.groupType == 2) {
    //   this.activeKey = "2";
    // }
    this.judgeProjectAddGroup();
    await this.selectDelayDay();
    this.getProjectDetail();
  },
  methods: {
    getCycleList(val, data) {
      this.allCycleTime = val || "";
      this.assessmentCycle_list = data || [];
      this.setTime(this.allCycleTime, this.project_data.endDate);
    },
    judgeProjectAddGroup() {
      _API.judgeProjectAddGroup({ id: this.id }).then((res) => {
        if (res.code == 200) {
          let status = res.data.status; //按钮状态，1隐藏 2置灰 3高亮
          this.btnStatus = status == 3 ? false : true;
        } else {
          this.$message.error(`获取按钮控制状态失败,${res.message}`);
        }
      });
    },
    getProjectDetail() {
      _API.getProjectDetail(this.id).then((res) => {
        if (res.code == 200) {
          this.project_data = res.data || {};
          this.setTime(this.allCycleTime, this.project_data.endDate);
        } else {
          this.$message.error(res.message || "获取详情失败");
        }
      });
    },
    selectDelayDay() {
      _API.selectDelayDay({ id: this.id }).then((res) => {
        if (res.code == 200) {
          this.delayDay = res.data.delayStatisticsDay || 0;
        } else {
          this.$message.error(`获取失败，${res.message}`);
        }
      });
    },
    setTime(day1, day2) {
      const nDay = this.$moment(day1).add(this.delayDay, "days");
      const endDate = this.$moment(day2);
      
      if (!day1) {
        this.endTime = this.project_data.endDate;
        return;
      }

      if (!day2) {
        this.endTime = nDay.format("YYYY-MM-DD");
        return;
      }

      if (nDay > endDate) {
        this.endTime = endDate.format("YYYY-MM-DD");
      } else {
        this.endTime = nDay.format("YYYY-MM-DD");
      }
    },
  },
};
</script>

<style scoped lang="less">
h1 {
  font-size: 18px;
}

.time-s {
  padding-bottom: 10px;
}

.tabs_CONTENT {
  border-top: 1px solid #eae4e4;
  padding: 15px;
}
</style>
