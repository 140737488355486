<template>
  <div class="box">
    <h2>考核周期</h2>
    <div>
      <a-radio-group v-model="activeTab" button-style="solid" style="margin: 20px 0;">
          <a-radio-button :value="1">
            自定义周期
          </a-radio-button>
          <a-radio-button :value="2">
            自定义周
          </a-radio-button>
          <a-radio-button :value="3">
            日
          </a-radio-button>
      </a-radio-group>
      <a-button
          :disabled="btnStatus"
          class="mr-20"
          type="primary"
          @click="showAssessmentCycleVisible"
          style="float: right;"
      >
        设置考核周期
      </a-button>
    </div>
    <div class="data-table" v-if="activeTab == 1">
      <a-table
          :loading="loading"
          :columns="columns"
          :data-source="list"
          :row-key="(record, index) => index"
          :pagination="false"
          :scroll="{ x: 1000}"
      >
        <div slot="customTitle">
          周期名称
          <a-tooltip>
            <template #title>
              设置自定义周期后，系统会自动生成一个“全周期”(时间范围为考核周期启动日-终止日)，可用于跟踪统计项目整体KPI完成情况。
            </template>
            <a-icon class="mr-8" type="info-circle"/>
          </a-tooltip>
        </div>
        <div slot="Cycle" slot-scope="text, record">
          <span>{{ record.cycleName || "-" }}</span>
        </div>
        <div slot="time" slot-scope="text, record">
          <span>{{ record.startDate || "-" }} - {{ record.endDate }}</span>
        </div>
        <div slot="activityTime" slot-scope="text, record">
          <div class="detail-item">
            <span class="detail-item__label">更新时间：</span>
            <span class="detail-item__value">{{ record.mtime || "-" }}</span>
          </div>
          <div class="detail-item">
            <span class="detail-item__label">更新人：</span>
            <span class="detail-item__value">{{ record.actor || "-" }}</span>
          </div>
        </div>
      </a-table>
      <base-pagination
          :current-page="pagination.current"
          :page-size="pagination.pageSize"
          :total="pagination.total"
          @onChange="handlePaginationChange"
          @onShowSizeChange="handlePaginationChange"
      />
    </div>
    <div v-if="activeTab == 2">
      <div v-if="weekData.isShowWeek">
        <div>已开启按周考核</div>
        <div>一周开始日：<span>{{ weekData.customWeek }}</span></div>
      </div>
      <div v-else>未开启按周考核</div>
    </div>
    <div v-if="activeTab == 3">
      <div v-if="dayData.isShowDay">已开启按日考核</div>
      <div v-else>未开启按日考核</div>
    </div>

    <assessmentCycleDrawer
        :visible="assessmentCycleVisible"
        :cycle-list="cycleList"
        :week-data="weekData"
        :day-data="dayData"
        :project-id="projectId"
        @handleCancel="handleCancel"
        :date5="date5"
        :project_data="JSON.stringify(getTemporaryParameters)"
    />
  </div>
</template>

<script>
import assessmentCycleDrawer from "../components/assessmentCycleDrawer";
import _API from "@/api/projectManagement";
import moment from "moment";
import { cloneDeep } from "lodash-es";
import { createNamespacedHelpers } from "vuex";

const {mapGetters} = createNamespacedHelpers("temporary_parameters");


export default {
  name: "assessmentCyclePage",
  components: {assessmentCycleDrawer},
  props: {
    projectId: {
      type: [String, Number],
    },
    kpiBusinessId: {
      type: [String, Number],
    },
    btnStatus: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(["getTemporaryParameters"])
  },
  data() {
    return {
      assessmentCycleVisible: false,
      loading: false,
      columns: [
        {
          scopedSlots: {customRender: "Cycle"},
          slots: {title: "customTitle"},
          width: 180,
        },
        {
          title: "时间范围",
          dataIndex: "",
          slots: {title: "customTitle"},
          scopedSlots: {customRender: "time"},
          width: 180,
        },
        {
          title: "天数",
          dataIndex: "days",
          width: 180,
        },
        {
          title: "更新信息",
          scopedSlots: {customRender: "activityTime"},
          width: 260,
        },
      ],
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      cycleList: [],
      disabled: false,
      endTimeFive: "",
      businessId: this.$route.query.kpiBusinessId || this.kpiBusinessId,
      date5: "",
      activeTab: 1,
      weekData: {},
      dayData: {},
    };
  },
  mounted() {
    this.getCycleList();
    this.getWeekList()
    this.getDayList()
  },
  methods: {
    getCycleList() {
      _API.getCycleList({kpiBusinessId: this.businessId}).then((res) => {
        if(res.code == 200) {
          this.list = res.data || [];
          this.list.forEach(item => {
            if(item.isAllCycle == 1) {
              this.endTimeFive = item.endDate; // 全周期
            }
          });
          // 当天日期
          let date = moment(new Date()).add("year", 0).format("YYYY-MM-DD");
          // 全周期 +5  包含第5天
          let date5 = this.endTimeFive ? moment(this.endTimeFive).add(6, "days").format("YYYY-MM-DD") : "";
          // 全周期 +5 不包含第5天
          let endTimeFive = this.endTimeFive ? moment(this.endTimeFive).format("YYYY-MM-DD") : "";

          this.date5 = date5;
          // 比较当前日期是否小于全周期+5
          if(this.list.length) {
            if(moment(date) < moment(date5)) {
              this.disabled = false;
            } else {
              this.disabled = true;
            }
            this.$emit("getCycleList", endTimeFive, this.list);
          } else {
            this.disabled = false;
          }

        } else {
          this.$message.error(res.message || "获取考核周期列表失败");
        }
      });
    },
    handlePaginationChange(page, size) {
      // console.log(page, size);
    },
    handleCancel() {
      this.cycleList = [];
      this.assessmentCycleVisible = false;
      this.getCycleList();
      this.getWeekList()
      this.getDayList()
    },
    showAssessmentCycleVisible() {
      if(this.list.length) {
        // this.list.forEach((item) => {
        //   if(item.isAllCycle == 0) {
        //     this.cycleList.push(cloneDeep(item));
        //   }
        // });
        this.cycleList = cloneDeep(this.list || [])?.filter(v => v.isAllCycle == 0);
      } else {
        for(let i = 0; i < 3; i++) {
          let obj = {
            cycleName: `第${ i + 1 }周期`,
            startDate: "",
            endDate: "",
            days: 0,
            businessId: this.kpiBusinessId,
            isAllCycle: 0,
          };
          this.cycleList.push(obj);
        }
      }
      this.assessmentCycleVisible = true;
    },
    getWeekList() {
      _API.getWeekList({businessId: this.businessId}).then(res => {
        if(res.code == 200) {
          this.weekData = res.data
        } else {
          this.$message.error(`获取自定义周数据失败,${res.message}`)
        }
      })
    },
    getDayList() {
      _API.getDayList({businessId: this.businessId}).then(res => {
        if(res.code == 200) {
          this.dayData = res.data
        } else {
          this.$message.error(`获取日数据失败,${res.message}`)
        }
      })
    },
  },
};
</script>


<style scoped lang="less">
.box {
  padding: 10px;
}

.detail-item {
  display: flex;
  font-weight: bold;

  &__value {
    flex: 1;
    font-weight: 400;
  }
}

.data-table {
  margin-top: 30px;
}
</style>
